import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import Image from 'common/src/components/Image';

import { useStaticQuery, graphql } from 'gatsby';
import { Link } from 'gatsby';

import BuildScalableImg from 'common/src/assets/image/hosting/Icons/Platform/Build Scalable Solutions.png';
import DesignedImg from 'common/src/assets/image/hosting/Icons/Platform/Designed for Low or No Data.png';
import IntegrateImg from 'common/src/assets/image/hosting/Icons/Platform/Integrate with Ease.png';
import LetImg from 'common/src/assets/image/hosting/Icons/Platform/Let the Platform Figure it Out.png';
import RapidImg from 'common/src/assets/image/hosting/Icons/Platform/Rapid & Flexible Deployment.png';

import '../../iconfloating.css';

const IconFloatingsection = ({
  sectionWrapper,
  row,
  col,
  col20,
  col30,
  col70,
  title,
  description,
  iconHead,
  iconsubHead,
  iconDescription,
  textArea,
}) => {
  return (
    <>
      <Box {...sectionWrapper} className="Icon_Floating_Container RapFlow">
        <Container>
          <Heading {...title} content="Why RAPFlow?" />
          <Text
            {...description}
            className="text_center"
            content="RAPFlow is the industry-leading choice for automating tedious tasks for extracting structured data and orchestrating an AI pipeline of tasks with no specialist AI or data knowledge."
          />
          <Fade bottom cascade delay={50}>
            <Box {...row} className="margin_auto flexjustifyaround">
              <Box {...col30} className="icontext_card icontext_card_whitebg">
                <Box className="flexaligncenter">
                  <ul>
                    <li>
                      <Image
                        src={LetImg}
                        alt="Let the Platform Figure it Out"
                        className="icon_image"
                      />
                    </li>
                    <li {...iconHead}>Let the Platform Figure it Out</li>
                    <li {...iconDescription}>
                      Built-in AutoDL means the platform figures out the
                      best-fit algorithm for your needs. Simply load the data
                      and let the platform work its magic.
                    </li>
                  </ul>
                </Box>
              </Box>
              <Box {...col30} className="icontext_card icontext_card_whitebg">
                <Box className="flexaligncenter">
                  <ul>
                    <li {...iconHead}>
                      <Image
                        src={DesignedImg}
                        alt="Designed for Low or No Data"
                        className="icon_image"
                      />
                    </li>
                    <li {...iconHead}>Designed for Low or No Data</li>
                    <li {...iconDescription}>
                      Reduce training time and effort to launch solutions
                      faster. Choose from a plethora of pre-trained models or
                      build your own model working off low data with our
                      optimized algorithms.
                    </li>
                  </ul>
                </Box>
              </Box>
              <Box {...col30} className="icontext_card icontext_card_whitebg">
                <Box className="flexaligncenter">
                  <ul>
                    <li>
                      <Image
                        src={BuildScalableImg}
                        alt="Build Scalable Solutions"
                        className="icon_image"
                      />
                    </li>
                    <li {...iconHead}>Build Scalable Solutions</li>
                    <li {...iconDescription}>
                      Build scalable solutions with ease that can handle
                      on-demand surges. The RAPFlow platform allows you to
                      deploy solutions with the ability to scale at a component
                      level.
                    </li>
                  </ul>
                </Box>
              </Box>
            </Box>
            <Box {...row} className="flexjustifycenter">
              <Box
                {...col30}
                className="icontext_card icontext_card_whitebg mr3"
              >
                <Box className="flexaligncenter">
                  <ul>
                    <li>
                      <Image
                        src={RapidImg}
                        alt="Rapid & Flexible Deployment"
                        className="icon_image"
                      />
                    </li>
                    <li {...iconHead}>Rapid & Flexible Deployment</li>
                    <li {...iconDescription}>
                      Don’t worry about time to launch. With RAPFlow, you can
                      experiment, train, build and deploy rapidly anywhere –
                      on-prem, or public or private cloud.
                    </li>
                  </ul>
                </Box>
              </Box>
              <Box {...col30} className="icontext_card icontext_card_whitebg">
                <Box className="flexaligncenter">
                  <ul>
                    <li>
                      <Image
                        src={IntegrateImg}
                        alt="Integrate with Ease"
                        className="icon_image"
                      />
                    </li>
                    <li {...iconHead}>Integrate with Ease</li>
                    <li {...iconDescription}>
                      Convenient API integrations enable the RAPFlow platform to
                      integrate with your existing systems, augmenting them.
                    </li>
                  </ul>
                </Box>
              </Box>
            </Box>
          </Fade>
        </Container>
      </Box>
      {/* end of rapflow */}
    </>
  );
};

IconFloatingsection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  col20: PropTypes.object,
  col30: PropTypes.object,
  col70: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  iconHead: PropTypes.object,
  iconsubHead: PropTypes.object,
  iconDescription: PropTypes.object,
  textArea: PropTypes.object,
};

IconFloatingsection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    id: 'IconFloating_section',
    className: 'IconFloating_section',
    pt: ['15px', '15px', '15px', '15px'],
    background: 'transparent',
    pb: ['30px', '30px', '30px', '30px'],
  },

  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  col: {
    className: 'col',
    width: [1, 0.45, 0.45, 0.45],
    pr: '15px',
    pl: '15px',
    mb: '20px',
  },
  col20: {
    className: 'col20',
    width: [0.4, 0.15, 0.15, 0.2],
  },
  col30: {
    className: 'col30',
    width: [1, 1, 0.3, 0.3],
  },
  col70: {
    className: 'col70',
    width: [1, 1, 0.7, 0.7],
  },
  iconHead: {
    fontSize: ['18px', '18px', '20px', '20px'],
    className: 'iconHead',
  },
  iconsubHead: {
    fontSize: ['18px', '18px', '20px', '20px'],
    className: 'iconsubHead',
  },
  textArea: {
    width: ['100%', '85%', '85%', '85%', '80%'],
    margin: '0 auto',
  },
  iconDescription: {
    className: 'iconDescription',
    fontSize: ['18px', '18px', '20px', '20px'],
  },
  title: {
    fontSize: ['30px', '30px', '35px', '40px', '40px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '0.015em',
    mb: '25px',
    pt: '25px',
    textAlign: 'center',
  },
  description: {
    fontSize: ['16px', '16px', '18px', '18px', '18px'],
    color: '#252525',
    lineHeight: '1.5',
    mt: '25px',
    mb: '25px',
  },
};

export default IconFloatingsection;
