import styled from 'styled-components';

const GridWrapper = styled.section`
  z-index: 2;
  .grid_container {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 25px;
    margin: 0 -25px;
  }
  .grid_col {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin: 0.5%;
    border-radius: 4px;
    // background-color: #fff;
    color: #252525;
    // color: #fff;
    position: relative;
  }
  .grid_col::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    // background-color: transparent;
    border: none;
    z-index: -1;
    border-radius: 4px;
    transform: scale(1);
    transition: all 0.4s ease-in-out;
  }
  .grid_col:hover::before {
    transform: scale(1.02);
  }
  .dark-bg {
    z-index: -1 !important;
  }
  .grid_header .grid_col {
    border: none;
    background-color: transparent;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 15px;
  }
  .grid_header .grid_col::before {
    border: none;
    background-color: transparent;
  }
  .empty_grid {
    border: none;
  }
  @media only screen and (max-width: 575px) {
    .grid_col {
      font-size: 1.7vw;
      padding: 5px 2px;
    }
    .grid_container {
      padding: 15px;
      margin: 0 -15px;
    }
    .grid_header .grid_col {
      font-size: 10px;
    }
  }
`;

export default GridWrapper;
