import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import GridWrapper from './grid.style';

import BgImageDark from '../BgImagedark';

const GridSection = ({
  grid,
  row,
  col16,
  col33,
  col66,
  title,
  subtitle,
  description,
}) => {
  return (
    <GridWrapper className="section_background_blue">
      <BgImageDark />
      <Container>
        <Box>
          <Heading content="All-in-one Box" {...title} />
          <Heading
            content="Full lifecycle AI Orchestration - every stage on a single platform"
            {...subtitle}
          />
          <Box {...grid}>
            <Box {...row} className="grid_header">
              <Box {...col16}>Create</Box>
              <Box {...col16}>Analyze</Box>
              <Box {...col16}>Train</Box>
              <Box {...col16}>Deploy</Box>
              <Box {...col16}>Monitor</Box>
              <Box {...col16}>Improve</Box>
            </Box>
            <Box {...row}>
              <Box {...col16}>Load Datasets</Box>
              <Box {...col16}>Characterize Data</Box>
              <Box {...col16}>Pre-trained Models</Box>
              <Box {...col16}>On-premise</Box>
              <Box {...col33}>Analyze Performance</Box>
            </Box>
            <Box {...row}>
              <Box {...col16}>Label Data</Box>
              <Box {...col16}>Analyze Data</Box>
              <Box {...col16}>AutoDL</Box>
              <Box {...col16}>Private Cloud</Box>
              <Box {...col33} className="empty_grid"></Box>
            </Box>
            <Box {...row}>
              <Box {...col33}>Generate Datasets</Box>
              <Box {...col16}>Unsupervised Learning</Box>
              <Box {...col16}>Public Cloud</Box>
              <Box {...col33} className="empty_grid"></Box>
            </Box>
            <Box {...row}>
              <Box {...col33}>Augment Data</Box>
              <Box {...col16}>Best-fit Model</Box>
              <Box {...col16}>Scalable Deployment</Box>
              <Box {...col33} className="empty_grid"></Box>
            </Box>
            <Box {...row}>
              <Box {...col33}>Fix Data</Box>
              <Box {...col66}>Continuous Learning</Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </GridWrapper>
  );
};

// Footer style props
GridSection.propTypes = {
  grid: PropTypes.object,
  row: PropTypes.object,
  col16: PropTypes.object,
  col33: PropTypes.object,
  col66: PropTypes.object,
  title: PropTypes.object,
  subtitle: PropTypes.object,
  description: PropTypes.object,
};

GridSection.defaultProps = {
  grid: {
    className: 'grid_container',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    // alignItems: 'center',
    justifyContent: 'flex-start',
    // ml: '-15px',
    // mr: '-15px',
  },
  col16: {
    pr: '15px',
    pl: '15px',
    width: ['15.666%', '15.666%', '15.666%', '15.666%', '15.666%'],
    className: 'grid_col',
  },
  col33: {
    pr: '15px',
    pl: '15px',
    width: ['32.333%', '32.333%', '32.333%', '32.333%', '32.333%'],
    className: 'grid_col',
  },
  col66: {
    pr: '15px',
    pl: '15px',
    width: ['65.666%', '65.666%', '65.666%', '65.666%', '65.666%'],
    className: 'grid_col',
  },
  title: {
    fontSize: ['30px', '30px', '35px', '40px', '40px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '0.015em',
    mb: '5px',
    pt: '0',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: ['20px', '20px', '25px', '25px', '25px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '0.015em',
    mb: '35px',
    pt: '5px',
    textAlign: 'center',
  },
  description: {
    fontSize: ['16px', '16px', '18px', '18px', '18px'],
    // color: '#343d48cc',
    color: '#252525',
    lineHeight: '1.5',
    mb: '25px',
  },
};

export default GridSection;
