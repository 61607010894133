import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
// import scrollTo from 'gatsby-plugin-smoothscroll';
import { List, ListItem } from '../hoverslider.style';

import Container from 'common/src/components/UI/Container';
// import { useStaticQuery, graphql } from 'gatsby';

import HoverImage1 from 'common/src/assets/image/hosting/Pages/Platform/HoverSlider/RAPFlow/Boost Operational Efficiency.svg';
import HoverImage2 from 'common/src/assets/image/hosting/Pages/Platform/HoverSlider/RAPFlow/Accuracy.svg';
import HoverImage3 from 'common/src/assets/image/hosting/Pages/Platform/HoverSlider/RAPFlow/Scalability.svg';
import HoverImage4 from 'common/src/assets/image/hosting/Pages/Platform/HoverSlider/RAPFlow/Pre-trained Models.svg';
import HoverImage5 from 'common/src/assets/image/hosting/Pages/Platform/HoverSlider/RAPFlow/Auto DL.svg';
import HoverImage6 from 'common/src/assets/image/hosting/Pages/Platform/HoverSlider/RAPFlow/Rapid Deployment.svg';
import HoverImage7 from 'common/src/assets/image/hosting/Pages/Platform/HoverSlider/RAPFlow/World-class Security.svg';
import HoverImage8 from 'common/src/assets/image/hosting/Pages/Platform/HoverSlider/RAPFlow/Integrate with Ease.svg';
import HoverImage9 from 'common/src/assets/image/hosting/Pages/Platform/HoverSlider/RAPFlow/Continuous Learning.svg';
import HoverImage10 from 'common/src/assets/image/hosting/Pages/Platform/HoverSlider/RAPFlow/Inbuilt Data Analytics.svg';

import '../hoverslider.css';

class HoverSliderSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverImg: HoverImage1,
      hoverImg2: HoverImage6,
      active: true,
    };
  }
  componentDidMount() {
    setTimeout(() => {
      var hoverList1 = document.querySelectorAll('.sliderData1 ul li');
      var hoverList2 = document.querySelectorAll('.sliderData2 ul li');

      for (let i = 0; i < hoverList1.length; i++) {
        hoverList1[i].addEventListener('mouseover', e => {
          let current = document.querySelectorAll('.sliderData1 ul li.active');
          if (current.length > 0) {
            current[0].className = current[0].className.replace(' active', '');
          }
          e.target.classList.add('active');
        });
      }
      for (let i = 0; i < hoverList2.length; i++) {
        hoverList2[i].addEventListener('mouseover', e => {
          let current = document.querySelectorAll('.sliderData2 ul li.active');
          if (current.length > 0) {
            current[0].className = current[0].className.replace(' active', '');
          }
          e.target.classList.add('active');
        });
      }
    }, 2000);
  }
  componentWillUnmount() {
    setTimeout(() => {
      var hoverList1 = document.querySelectorAll('.sliderData1 ul li');
      var hoverList2 = document.querySelectorAll('.sliderData2 ul li');

      for (let i = 0; i < hoverList1.length; i++) {
        hoverList1[i].addEventListener('mouseover', e => {
          let current = document.querySelectorAll('.sliderData1 ul li.active');
          if (current.length > 0) {
            current[0].className = current[0].className.replace(' active', '');
          }
          e.target.classList.add('active');
        });
      }
      for (let i = 0; i < hoverList2.length; i++) {
        hoverList2[i].addEventListener('mouseover', e => {
          let current = document.querySelectorAll('.sliderData2 ul li.active');
          if (current.length > 0) {
            current[0].className = current[0].className.replace(' active', '');
          }
          e.target.classList.add('active');
        });
      }
    }, 2000);
  }
  render() {
    const {
      row,
      // col,
      // col2,
      col3,
      col5,
      // textArea,
      title,
      description,
    } = this.props;

    return (
      <>
        <Box className="hoverSlider Dekstop">
          <Container>
            {/* <Fade bottom cascade delay={50}> */}
            <Heading {...title} content="Why RAPFlow?" />
            <Text
              {...description}
              className="text_center"
              content="RAPFlow is the industry-leading choice for automating tedious tasks for extracting structured data and orchestrating an AI pipeline of tasks."
            />
            <Box {...row}>
              <Box {...col5} className="sliderImage">
                <div className="empty_space20" />
                <Fade left cascade>
                  <Image
                    src={this.state.hoverImg}
                    className="icon_image"
                    alt="icon_image"
                  />
                </Fade>
                <div className="empty_space20" />
              </Box>
              <Box {...col5} className="sliderData sliderData1">
                <List>
                  <ListItem
                    onMouseEnter={() => {
                      this.setState({
                        hoverImg: HoverImage1,
                      });
                    }}
                    className="active"
                    // data-url={HoverImage1}
                  >
                    AI automation accelerates processes and cuts costs enabling
                    higher throughput and maximum operational efficiency.
                  </ListItem>
                  <ListItem
                    onMouseEnter={() => {
                      this.setState({
                        hoverImg: HoverImage2,
                      });
                    }}
                    // data-url={HoverImage1}
                  >
                    Highly accurate text recognition whether handwritten or
                    scanned as well as accurate intent classification.
                  </ListItem>
                  <ListItem
                    onMouseEnter={() => {
                      this.setState({
                        hoverImg: HoverImage3,
                      });
                    }}
                  >
                    Scale at will to handle surge in demand or expand across
                    different branches of the organization.
                  </ListItem>
                  <ListItem
                    onMouseEnter={() => {
                      this.setState({
                        hoverImg: HoverImage4,
                      });
                    }}
                  >
                    Forget about the headache of training with pre-trained
                    models available for numerous applications on the RAPFlow
                    platform.
                  </ListItem>
                  <ListItem
                    onMouseEnter={() => {
                      this.setState({
                        hoverImg: HoverImage5,
                      });
                    }}
                  >
                    Automated Deep Learning determines the best-fit algorithm
                    and provides the optimal solution within specified
                    constraints such as a restricted time frame.
                  </ListItem>
                </List>
              </Box>
            </Box>
            <Box {...row}>
              <Box {...col5} className="sliderData sliderData2">
                <List>
                  <ListItem
                    onMouseEnter={() => {
                      this.setState({
                        hoverImg2: HoverImage6,
                      });
                    }}
                    className="active"
                  >
                    Don’t worry about time to launch. Deploy quickly with our
                    convenient range of flexible deployment models.
                  </ListItem>
                  <ListItem
                    onMouseEnter={() => {
                      this.setState({
                        hoverImg2: HoverImage7,
                      });
                    }}
                  >
                    Keep your data and processes secure with world-class
                    security measures built into the RAPFlow platform.
                  </ListItem>
                  <ListItem
                    onMouseEnter={() => {
                      this.setState({
                        hoverImg2: HoverImage8,
                      });
                    }}
                  >
                    Convenient API integrations enable the RAPFlow platform to
                    integrate with you existing systems, augmenting them.
                  </ListItem>
                  <ListItem
                    onMouseEnter={() => {
                      this.setState({
                        hoverImg2: HoverImage9,
                      });
                    }}
                  >
                    The RAPFlow platform learns and improves with every
                    iteration and this contributes to increased efficiency.
                  </ListItem>
                  <ListItem
                    onMouseEnter={() => {
                      this.setState({
                        hoverImg2: HoverImage10,
                      });
                    }}
                  >
                    Highly convenient ability to review utilization and
                    performance with inbuilt data analytics and usage metrics.
                  </ListItem>
                </List>
              </Box>
              <Box {...col5} className="sliderImage">
                <div className="empty_space20" />
                <Fade right cascade>
                  <Image
                    src={this.state.hoverImg2}
                    className="icon_image"
                    alt="icon_image"
                  />
                </Fade>
                <div className="empty_space20" />
              </Box>
            </Box>
            {/* </Fade> */}
          </Container>
        </Box>

        <Box className="hoverSlider Mobile">
          <Container>
            {/* <Fade left cascade> */}
            <Heading {...title} content="Why RAPFlow?" />
            <Text
              {...description}
              className="text_center"
              content="RAPFlow is the industry-leading choice for automating tedious tasks for extracting structured data and orchestrating an AI pipeline of tasks."
            />
            <Box {...row} className="flexjustifycenter">
              <Box {...col3}>
                <Image src={HoverImage1} alt="test" className="icon_image" />
                <Text
                  {...description}
                  content="AI automation accelerates processes and cuts costs enabling higher throughput and maximum operational efficiency."
                />
              </Box>
              <Box {...col3}>
                <Image src={HoverImage2} alt="test" className="icon_image" />
                <Text
                  {...description}
                  content="Highly accurate text recognition whether handwritten or scanned as well as accurate intent classification."
                />
              </Box>
              <Box {...col3}>
                <Image src={HoverImage3} alt="test" className="icon_image" />
                <Text
                  {...description}
                  content="Scale at will to handle surge in demand or expand across different branches of the organization."
                />
              </Box>
              <Box {...col3}>
                <Image src={HoverImage4} alt="test" className="icon_image" />
                <Text
                  {...description}
                  content="Forget about the headache of training with pre-trained models available for numerous applications on the RAPFlow platform."
                />
              </Box>
              <Box {...col3}>
                <Image src={HoverImage5} alt="test" className="icon_image" />
                <Text
                  {...description}
                  content="Automated Deep Learning determines the best-fit algorithm and provides the optimal solution within specified constraints such as a restricted time frame."
                />
              </Box>
              <Box {...col3}>
                <Image src={HoverImage6} alt="test" className="icon_image" />
                <Text
                  {...description}
                  content="Don’t worry about time to launch. Deploy quickly with our convenient range of flexible deployment models."
                />
              </Box>
              <Box {...col3}>
                <Image src={HoverImage7} alt="test" className="icon_image" />
                <Text
                  {...description}
                  content="Keep your data and processes secure with world-class security measures built into the RAPFlow platform."
                />
              </Box>
              <Box {...col3}>
                <Image src={HoverImage8} alt="test" className="icon_image" />
                <Text
                  {...description}
                  content="Convenient API integrations enable the RAPFlow platform to integrate with you existing systems, augmenting them."
                />
              </Box>
              <Box {...col3}>
                <Image src={HoverImage9} alt="test" className="icon_image" />
                <Text
                  {...description}
                  content="The RAPFlow platform learns and improves with every iteration and this contributes to increased efficiency."
                />
              </Box>
              <Box {...col3}>
                <Image src={HoverImage10} alt="test" className="icon_image" />
                <Text
                  {...description}
                  content="Highly convenient ability to review utilization and performance with inbuilt data analytics and usage metrics."
                />
              </Box>
            </Box>
            {/* </Fade> */}
          </Container>
        </Box>
      </>
    );
  }
}

HoverSliderSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  col2: PropTypes.object,
  col3: PropTypes.object,
  col5: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  textArea: PropTypes.object,
};

HoverSliderSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '0',
    mr: '0',
    alignItems: 'center',
  },
  col: {
    className: 'col',
    width: [1, 0.6, 0.6, 0.6],
    pr: '15px',
    pl: '15px',
    mb: '20px',
    mt: '20px',
  },
  col2: {
    className: 'col2',
    width: [1, 0.4, 0.4, 0.4],
    pr: '0px',
    pl: '0',
    mb: '20px',
    mt: '20px',
  },
  col3: {
    className: 'col3',
    width: [1, 0.4, 0.4, 0.4],
    pr: '15px',
    pl: '15px',
    mb: '20px',
    mt: '20px',
  },
  col5: {
    className: 'col5',
    width: [1, 0.49, 0.49, 0.49],
    pr: '0',
    pl: '0',
    mb: '10px',
    mt: '10px',
  },
  textArea: {
    width: ['100%', '85%', '85%', '85%', '80%'],
    margin: '0 auto',
  },
  title: {
    fontSize: ['30px', '30px', '35px', '40px', '40px'],
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '0.015em',
    mb: '25px',
    pt: '25px',
    textAlign: 'center',
  },
  description: {
    fontSize: ['16px', '16px', '18px', '18px', '18px'],
    color: '#252525',
    lineHeight: '1.5',
    mt: '25px',
    mb: '25px',
  },
};

export default HoverSliderSection;
