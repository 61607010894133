import React from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../../containers/Hosting/Navbar';
import Footer from '../../containers/Hosting/Footer';
import Ctasection from '../../containers/Hosting/Ctasection';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../../components/seo';

import ScrollfixedRapFlow from '../../containers/Hosting/ScrollFixed/rapflow';
import BannerSectionPlatform from '../../containers/Hosting/Banner/BannerPlatform';
import HoverSliderSection from '../../containers/Hosting/HoverSlider/RapFlow';
// import HoverSliderSection from '../../containers/Hosting/HoverSlider';
import InfoPlatformSection from '../../containers/Hosting/InfoPlatform';
import GridSection from '../../containers/Hosting/GridSection';
import IconFloatingsection from '../../containers/Hosting/IconFloating/Platform/RapFlo';
import TopRibbon from '../../containers/Hosting/TopRibbon';

const RAPFlowPage = () => {
  const name = 'RAPFlow';
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        <SEO
          title="Full life cycle AI orchestration | RAPFlow"
          description="Build an Automated AI pipeline in days or weeks, not months. Extract structured data from unstructured content in a jiffy. Book a free demo here."
        />
        <ResetCSS />
        <GlobalStyle />
        <TopRibbon />
        <ContentWrapper className="RAPFlowPage">
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSectionPlatform name={name} />
          <InfoPlatformSection name={name} />
          <GridSection />
          <ScrollfixedRapFlow />
          {/* <HoverSliderSection name={name} /> */}
          <IconFloatingsection />
          <Ctasection name={name} />
          <Footer />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};

export default RAPFlowPage;
