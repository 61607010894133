import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import Image from 'common/src/components/Image';
import { List, ListItem } from '../scrollFixed.style';
import '../scrollfixed.css';

import scrollTo from 'gatsby-plugin-smoothscroll';

import { Link } from 'gatsby';

import AnalyzeImg from 'common/src/assets/image/hosting/ScrollFixed/Platform/Rapflow/Analyze.svg';
import CreateImg from 'common/src/assets/image/hosting/ScrollFixed/Platform/Rapflow/Create.svg';
import DeployImg from 'common/src/assets/image/hosting/ScrollFixed/Platform/Rapflow/Deploy.svg';
import ImproveImg from 'common/src/assets/image/hosting/ScrollFixed/Platform/Rapflow/Improve.svg';
import MonitorImg from 'common/src/assets/image/hosting/ScrollFixed/Platform/Rapflow/Monitor.svg';
import TrainImg from 'common/src/assets/image/hosting/ScrollFixed/Platform/Rapflow/Train.svg';

class ScrollfixedRapFlow extends React.Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      function ScrollFixedRapFlow() {
        const content = document.querySelector(
          '.scrollmain_right_content.rapflow'
        );
        if (content != null) {
          const isInViewport = function(data) {
            if (data != null) {
              let bounding = data.getBoundingClientRect();
              let boundingTopOffset = bounding.top - 105;
              let boundingBottom = bounding.bottom;
              // let boundingBottomOffset = bounding.bottom;
              return boundingTopOffset <= 0 && boundingBottom >= 0;
            }
          };

          const content1 = document.querySelector(
            '.scrollmain_right_content.rapflow._ct1'
          );
          const content2 = document.querySelector(
            '.scrollmain_right_content.rapflow._ct2'
          );
          const content3 = document.querySelector(
            '.scrollmain_right_content.rapflow._ct3'
          );
          const content4 = document.querySelector(
            '.scrollmain_right_content.rapflow._ct4'
          );
          const content5 = document.querySelector(
            '.scrollmain_right_content.rapflow._ct5'
          );
          const content6 = document.querySelector(
            '.scrollmain_right_content.rapflow._ct6'
          );

          if (isInViewport(content1)) {
            document
              .querySelectorAll('.scrollmain_left_items li')[0]
              .classList.add('active');
          } else {
            document
              .querySelectorAll('.scrollmain_left_items li')[0]
              .classList.remove('active');
          }
          if (isInViewport(content2)) {
            document
              .querySelectorAll('.scrollmain_left_items li')[1]
              .classList.add('active');
          } else {
            document
              .querySelectorAll('.scrollmain_left_items li')[1]
              .classList.remove('active');
          }
          if (isInViewport(content3)) {
            document
              .querySelectorAll('.scrollmain_left_items li')[2]
              .classList.add('active');
          } else {
            document
              .querySelectorAll('.scrollmain_left_items li')[2]
              .classList.remove('active');
          }
          if (isInViewport(content4)) {
            document
              .querySelectorAll('.scrollmain_left_items li')[3]
              .classList.add('active');
          } else {
            document
              .querySelectorAll('.scrollmain_left_items li')[3]
              .classList.remove('active');
          }
          if (isInViewport(content5)) {
            document
              .querySelectorAll('.scrollmain_left_items li')[4]
              .classList.add('active');
          } else {
            document
              .querySelectorAll('.scrollmain_left_items li')[4]
              .classList.remove('active');
          }
          if (isInViewport(content6)) {
            document
              .querySelectorAll('.scrollmain_left_items li')[5]
              .classList.add('active');
          } else {
            document
              .querySelectorAll('.scrollmain_left_items li')[5]
              .classList.remove('active');
          }
        }
      }

      window.addEventListener('scroll', ScrollFixedRapFlow);
    }
  }
  render() {
    const {
      sectionWrapper,
      row,
      col,
      title,
      titleSlider,
      description,
      descriptionSlider,
      textArea,
      contentArea,
      listitems,
      ...props
    } = this.props;
    return (
      <>
        <Box {...sectionWrapper}>
          <Container>
            <Box className="scrollmain">
              <Heading {...title} content="Full Lifecycle AI Orchestration" />
              <Box className="scrollmain_left">
                <Text
                  {...description}
                  content="Transform your business with a powerful, automated AI pipeline built in mere days or weeks NOT months. Building your AI pipeline on the RAPFlow platform is a breeze – no specialist knowledge is required."
                />
                <div className="empty_space20" />
                <List className="scrollmain_left_items">
                  <ListItem {...listitems} onClick={() => scrollTo('#__ct1')}>
                    Create
                  </ListItem>
                  <ListItem {...listitems} onClick={() => scrollTo('#__ct2')}>
                    Analyze
                  </ListItem>
                  <ListItem {...listitems} onClick={() => scrollTo('#__ct3')}>
                    Train
                  </ListItem>
                  <ListItem {...listitems} onClick={() => scrollTo('#__ct4')}>
                    Deploy
                  </ListItem>
                  <ListItem {...listitems} onClick={() => scrollTo('#__ct5')}>
                    Monitor
                  </ListItem>
                  <ListItem {...listitems} onClick={() => scrollTo('#__ct6')}>
                    Improve
                  </ListItem>
                </List>
                {/* <div className="empty_space20" /> */}
              </Box>
              <Box className="scrollmain_right">
                <Box className="scrollmain_right_container">
                  <Box className="scrollmain_right_content rapflow _ct1">
                    <Box id="__ct1" className="topic" />
                    <Heading {...titleSlider} content="Create" />
                    <Image src={CreateImg} alt="Create" />
                    <Text
                      {...descriptionSlider}
                      content="Create datasets for training models with dataset generation, labelling and augmentation capabilities all in one box. Whether it is image annotation or creation of intelligent synthetic data that closely resembles your data set, or cleanup of existing dataset, RAPFlow does it all."
                    />
                  </Box>
                  <Box className="scrollmain_right_content rapflow _ct2">
                    <Box id="__ct2" className="topic" />
                    <Heading {...titleSlider} content="Analyze" />
                    <Image src={AnalyzeImg} alt="Analyze" />
                    <Text
                      {...descriptionSlider}
                      content="Analysis provides insights to help you identify dataset errors and take corrective action. Clean data is the foundation for best results and efficiency. Ensure consistency of data and leverage data augmentation or cleanup to improve the quality of your data."
                    />
                  </Box>
                  <Box className="scrollmain_right_content rapflow _ct3">
                    <Box id="__ct3" className="topic" />
                    <Heading {...titleSlider} content="Train" />
                    <Image src={TrainImg} alt="Train" />
                    <Text
                      {...descriptionSlider}
                      content="Low data or no data is no longer a hindrance to get a working model. Use our pre-trained models to work in such conditions and get up and running quickly. You can also explore our unsupervised learning algorithms that can work off unlabeled data."
                    />
                  </Box>
                  <Box className="scrollmain_right_content rapflow _ct4">
                    <Box id="__ct4" className="topic" />
                    <Heading {...titleSlider} content="Deploy" />
                    <Image src={DeployImg} alt="Deploy" />
                    <Text
                      {...descriptionSlider}
                      content="You can experiment, train, build and deploy rapidly anywhere – on-prem, or public or private cloud. Launch quickly with swift deployment. Deploy for scalability with the ability to scale individual components."
                    />
                  </Box>
                  <Box className="scrollmain_right_content rapflow _ct5">
                    <Box id="__ct5" className="topic" />
                    <Heading {...titleSlider} content="Monitor" />
                    <Image src={MonitorImg} alt="Monitor" />
                    <Text
                      {...descriptionSlider}
                      content="Ongoing monitoring and analysis of performance provides insights that enable further improvement of deployed models. Comprehensive measures and statistics on model performance are available, all in one platform."
                    />
                  </Box>
                  <Box className="scrollmain_right_content rapflow _ct6">
                    <Box id="__ct6" className="topic" />
                    <Heading {...titleSlider} content="Improve" />
                    <Image src={ImproveImg} alt="Improve" />
                    <Text
                      {...descriptionSlider}
                      content="Continuous learning ensures that deployed models continue to improve over time, increasing efficiency for better results. Integrate with our quality check app to enable faster learning."
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </>
    );
  }
}

ScrollfixedRapFlow.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  titleSlider: PropTypes.object,
  description: PropTypes.object,
  descriptionSlider: PropTypes.object,
  textArea: PropTypes.object,
  contentArea: PropTypes.object,
  listitems: PropTypes.object,
};

ScrollfixedRapFlow.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['30px', '30px', '30px', '30px', '30px'],
    pb: ['40px', '40px', '40px', '40px', '40px'],
    // pb: ['60px', '80px', '40px', '80px', '80px'],
    id: 'scroll_fixed',
    backgroundColor: '#191c3c',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '49%', '49%'],
  },
  textArea: {
    width: ['100%', '100%', '100%', '49%', '49%'],
  },
  contentArea: {
    width: ['100%', '100%', '80%', '80%', '80%'],
    margin: '0 auto',
  },
  title: {
    fontSize: ['30px', '30px', '35px', '40px', '40px'],
    fontWeight: '300',
    // color: '#0f2137',
    color: '#fff',
    textAlign: 'center',
    letterSpacing: '-0.025em',
    mb: '50px',
    pt: '20px',
  },
  description: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    // color: '#343d48cc',
    // color: '#252525',
    color: '#fff',
    lineHeight: '1.5',
    mt: '30px',
    mb: '5px',
  },
  titleSlider: {
    fontSize: ['22px', '22px', '25px', '30px', '30px'],
    fontWeight: '300',
    // color: '#0f2137',
    color: '#fff',
    letterSpacing: '-0.025em',
    mb: '30px',
    pt: '0px',
    textAlign: 'center',
    className: 'scrollFixTitle',
  },
  descriptionSlider: {
    fontSize: ['16px', '16px', '16px', '16px', '16px'],
    // color: '#343d48cc',
    // color: '#252525',
    color: '#fff',
    lineHeight: '1.5',
    mt: '25px',
    mb: '5px',
    textAlign: 'center',
  },
  listitems: {
    fontSize: ['16px', '18px', '18px', '18px', '18px'],
    color: '#fff',
    lineHeight: '1.5',
    mb: '15px',
  },
};

export default ScrollfixedRapFlow;
