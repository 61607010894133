import styled from 'styled-components';

const List = styled.ul``;
const ListItem = styled.li`
  margin-bottom: 30px;
  line-height: 1.5;
  position: relative;
  text-align: justify;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
  }
`;

export { List, ListItem };
